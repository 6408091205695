import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { getNft } from '../../store/features/nftSlice';

import { useParams } from "react-router-dom";

import DotsIcon from '../../assets/images/dots.svg'
import EditIcon from "../../assets/images/edit-green.svg";

import '../projectDetails/details.scss';
import NFTTop from "../../components/nftDetails/NFTTop";
import NFTInfo from "../../components/nftDetails/NFTInfo";
import NFTMainDetails from "../../components/nftDetails/NFTMainDetails";
import NFTProperties from "../../components/nftDetails/NFTProperties";
import NFTAttributes from "../../components/nftDetails/NFTAttributes";
import NavTop from "../../components/common/NavTop";
import Button from "../../components/Elements/button/button";

const NFTDetails = () => {
    const dispatch = useDispatch();
    const { nft } = useSelector(data => data.nft);

    const {id} = useParams();

    const [selected, setSelected] = useState('main');
    const [menuOpen, setMenuOpen] = useState(false);
    const [edit, setEdit] = useState(false)

    useEffect(() => {
        dispatch(getNft(id));
    }, [id]);

    const changeSelected = s => {
        setSelected(s);
        setMenuOpen(false);
    }

    const getSelectedLabel = () => {
        if (selected === 'main') {
            return 'Main details';
        }
         else if (selected === 'properties') {
            return 'NFT properties';
        }
        else if (selected === 'attributes') {
            return 'Attributes';
        }
    }

    const onSave = () => {
    setEdit(false);
    }

    return (
        <div className="flex-column flex-1">
            <div className="mb-6 mt-8 ml-6">
                <NavTop names={[{text: "NFTs", path: "/meta/nft"}, {text: nft?.name}]} edit={false} done={false}/>
            </div>           
             <div className="flex ml-6">
                <NFTInfo nft={nft}/>
                <div className="card flex-column card project-details mx-6">
                    <div className="flex items-center justify-between mx-6 mt-6">
                        <div className="flex items-center">
                            <label className="bold-text font-24">{getSelectedLabel(selected)}</label>
                        </div>
                        <div className="flex items-center">
                            {selected !== 'attributes' && !edit &&
                                <div className="flex items-center justify-center edit-btn cursor"  onClick={() => setEdit(true)}>
                                    <img src={EditIcon} alt="edit"/>
                                    <label className="bold-text font-14 violet-light-text ml-2">Edit</label>
                            </div>}
                            {edit && selected !== 'attributes'  &&
                            <div className="flex justify-center">
                                <Button
                                    className="secondary-hover"
                                    label="Cancel"
                                    borderRadius="12px"
                                    onClick={() => setEdit(false)}
                                    secondary={true}
                                    size="average"
                                    style={{ flex: 1 }}
                                    bgColor='#FFFFFF'
                                    borderColor="#24CBB1"
                                    color="#24CBB1"
                                    borderWidth="1px"
                                    width="112px"
                                    height="32px"
                                />
                                <Button
                                    label='Save'
                                    onClick={() => onSave()}
                                    size="average"
                                    style={{ flex: 1, marginLeft: 24 }}
                                    bgColor='#24CBB1'
                                    borderColor="#24CBB1"
                                    color='#FFFFFF'
                                    borderRadius="12px"
                                    borderWidth="1px"
                                    width="112px"
                                    height="32px"
                                />
                            </div>
                            }
                            <div className="flex items-center relative ml-4" onBlur={() => setMenuOpen(false)} tabIndex={1}>
                                <img className="cursor" src={DotsIcon} onClick={() => setMenuOpen(!menuOpen)}/>
                                {menuOpen &&
                                <div className="card flex-column pxy-4-2  project-details-menu">
                                    <div className="flex pxy-3-4 project-details-menu-item cursor"
                                        onClick={() => changeSelected('main')}>
                                        <label className="regular-text">Main Details</label>
                                    </div>
                                    {/* <div className="flex pxy-3-4 project-details-menu-item cursor"
                                        onClick={() => changeSelected('properties')}>
                                        <label className="regular-text">NFT properties</label>
                                    </div> */}
                                    <div className="flex pxy-3-4 project-details-menu-item cursor"
                                        onClick={() => changeSelected('attributes')}>
                                        <label className="regular-text">Attributes</label>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="nft-details-list">
                        {selected === 'main' && <NFTMainDetails nft={nft} edit={edit}/>}
                        {/* {selected === 'properties' && <NFTProperties nft={nft} edit={edit}/>} */}
                        {selected === 'attributes' && <NFTAttributes nft={nft}/>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NFTDetails;