import React from 'react'
import moment from 'moment';
import PlaceholderImage from '../../assets/images/nft-placeholder.svg'
import { useNavigate } from "react-router-dom";
import Image from "../image";
import { getNftLifecycleColor } from '../../helpers/utils';

import './styles.scss'

const NftCard = ({nft}) => {
    const navigate = useNavigate();

    return (
        nft ? <div className={"card nft-card flex items-center justify-between cursor mx-6 mt-2 mb-4"}
                   onClick={() => navigate(`/meta/nft/${nft.id}`)}>
            <div className={"card-left flex justify-start gap-4"}>
                <Image className={"nft-image"} size={85} media={nft?.image?.media} />
                <div className={"w-full"}>
                    <div className="flex justify-between items-center   ">
                        <label className={"bold-text my-2 mr-2"}>{nft.name}</label>
                        {/* {nft.series.issue && nft.series?.total && <span>{nft.series.issue && `${nft.series?.issue} of ${nft.series?.total}`}</span> } */}
                    </div>
                    <span className="regular-text font-12 o05 nft-desc">{nft.description}</span>
                </div>
            </div>

            <div className={"card-middle flex-column items-start justify-between gap-2"}>
                <div><span className="regular-text o05 mr-1 font-12">Carbon:</span></div>
                <div><span className="regular-text o05 mr-1 font-12">Type:</span><span className='regular-text font-12'>{nft.nft_type?.toLowerCase().charAt(0).toUpperCase() + nft.nft_type?.toLowerCase().slice(1)}</span></div>
                <div><span className="regular-text o05 mr-1 font-12">Mint date:</span>{nft.blockchains?.[0]?.minted_date ? <span className='regular-text font-12'>{moment.unix(nft.blockchains?.[0]?.minted_date).format('DD/MM/YYYY')}</span> : <span className='regular-text font-12 o03'>no date</span>}</div>
            </div>

            <div className={"card-right flex-column items-start justify-between gap-2"}>
                <div className='flex items-center justify-between w-full'>
                    <div><span className="regular-text o05 mr-1 font-12">Owner:</span><span className='regular-text font-12'>{nft.owner?.contact?.name}</span></div>
                    <div className='nft-lifecycle flex items-center justify-center' style={{background: getNftLifecycleColor(nft.lifecycle)}}>
                        <label className='medium-text font-10 white-text'>{nft.lifecycle}</label>
                    </div>
                </div>
                <div><span
                    className="regular-text o05 mr-1 font-12">Wallet:</span> {nft.owner?.wallet?.wallet_address}
                </div>
                <div className={"flex gap-6"}>
                    <div><span className="regular-text o05 mr-1 font-12">Token:</span><span className='regular-text font-12'>{nft.blockchains?.[0]?.token.id}</span></div>
                    <div><span className="regular-text o05 mr-1 font-12">Type:</span><span className='regular-text font-12'>{nft.blockchains?.[0]?.token.token_type}</span>
                    </div>
                </div>
            </div>
        </div> : <></>
    )
}

export default NftCard;
