import React, { useEffect, useState, useContext, useCallback, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { addToast } from "../../../store/features/toastSlice";
import uuid from "react-uuid";

import { getCampaignRetirements, setRetirements } from "../../../store/features/campaignSlice";
import Header from "../../header";
import McCheckbox from "../../McCheckbox";
import Loading from '../../../assets/images/loading.svg';
import { getNftLifecycleColor } from '../../../helpers/utils';
import { OrganisationContext } from "../../../context/organisationContext";
import InfiniteScroll from "react-infinite-scroll-component";
import AddIcon from "../../../assets/images/add.svg";
import CampaignRetirement from "../CampaignRetirment";
import { ReactComponent as RefreshIcon } from "../../../assets/images/refresh-green.svg";
import UpdateRetirementPopup from "../../campaignNft/updateRetirementPopup";

import './style.scss'
import moment from "moment";

const CampaignRetirements = ({campaignId, campaign}) => {
    const ref = useRef();

    const dispatch = useDispatch();
    const { retirements, loading, retirementLoading } = useSelector(data => data.campaign);
    const [c, setC] = useState(0)
    const [n, setN] = useState(0);
    const { checkPermission, hasPermission, setModal } = useContext(OrganisationContext);
    const [selectAll, setSelectAll] = useState(false);
    const [lifecycles, setLifecycles] = useState([{ id: 3, name: 'NEEDS NFT', color: 'rgb(36, 203, 177)', type: 'CREATE', selected: false}, { id: 1, name: 'PENDING', color: '#FBBF66', type: 'PENDING', selected: false}, { id: 2, name: 'MINTED', color: '#5CCFF3', type: 'MINTED', selected: false}])
    const menuItems = [ {id: 1, name: 'Edit'}];
    const [showRetirement, setShowRetirement] = useState();

    const loadRetirements  = useCallback(async (page, merge)  => {
        dispatch(getCampaignRetirements({campaignId: campaignId, page, nftTypes: lifecycles.filter(l => l.selected).map(l => l.type), merge }));
    }, [campaignId, lifecycles, dispatch]);

    useEffect(() => {
            loadRetirements(0, false);
    }, [loadRetirements, lifecycles, c, n]);

    const fetchMoreTransactions = () => {
        loadRetirements(retirements?.number + 1, true);
    };

    const onCreateNft = () => {
        if(!selectAll && retirements.content.filter(t => t.selected).length === 0)
        dispatch(addToast({
            error: true,
            title: "Error",
            text: `Please select retirements`,
            id: uuid(),
        }))
        else
            setModal({
                type: 'create-retirement-nft',
                content: {
                    top: true,
                    campaign: campaignId,
                    retirements: retirements.content.filter(t => t.selected),
                    onSuccess: () => setC(moment.unix())
                }
            })
    }

    const onSelectAll = () => {
        setSelectAll(!selectAll);
        dispatch(setRetirements({...retirements, content: retirements.content.map(t =>  ({...t, selected: false }))}))
    }

    const onSelectRetirement = tx => {
        setSelectAll(false);
        dispatch(setRetirements({...retirements, content: retirements.content.map(t => t.id === tx.id ? ({...t, selected: !t.selected}) : t)}))
    }

    const onMintNfts = () => {
        setModal({
            type: 'mint-nft',
            content: {
                top: true,
                campaign: campaign,
            }
        })
    }

    const onMenuItemClick = (item, ret) => {
        if(item.name === 'Edit') {
            setShowRetirement(ret)
        } 
    }

    return(
        <div className="flex-column campaign-retirements">
            <div className="flex items-center justify-between mx-6 my-4 no-select">
                <div className="flex items-center">
                    <div className="flex items-center cursor" onClick={onSelectAll}>
                        <McCheckbox checked={selectAll} />
                        <label className="regular-text ml-2">Select all</label>
                    </div>
                    <div className="flex items-center justify-center cursor action-btn ml-6" style={{opacity: hasPermission("NFT_MANAGE") ? 1 : 0.5 }} onClick={() => checkPermission(() => onCreateNft(), "NFT_MANAGE")}>
                        <img src={AddIcon} alt="add" />
                        <label className="bold-text font-14 violet-light-text ml-2">Create NFTs</label>
                    </div>
                    <div className="flex items-center justify-center cursor action-btn ml-6" style={{opacity: hasPermission("NFT_MANAGE") ? 1 : 0.5 }} onClick={() => checkPermission(() => onMintNfts(), "NFT_MANAGE")}>
                        <label className="bold-text font-14 violet-light-text">Mint NFTs</label>
                    </div>
                    <div className="flex items-center justify-center cursor action-btn ml-6" onClick={() => setC(moment.unix())}>
                        <RefreshIcon />
                    </div>
                </div>
                <div className="flex items-center no-select">
                        {lifecycles?.map(lifecycle => 
                        <div 
                            key={lifecycle.id}
                            className="flex items-center justify-center lifecycle-selector ml-2 cursor" 
                            onClick={() => setLifecycles(lifecycles.map(l => l.id === lifecycle.id ? ({...l, selected: !l.selected}) : l))}
                            style={{background: lifecycle.selected ? lifecycle.color : 'rgb(170, 186, 183, 0.1)'}}
                            >
                            <label className="bold-text font-12" style={{color: lifecycle.selected ? '#FFFFFF' : '#020819'}}>{lifecycle.name}</label>
                        </div>)}
                </div>
            </div>
            <div className="camp-ret-grid camp-ret-header border-top border-bottom">
                    <label className="font-12 semibold-text px-6 py-4 one-line">PROJECT</label>
                    <label className="font-12 semibold-text px-6 py-4 one-line">RETIREMENT DATE</label>
                    <label className="font-12 semibold-text px-6 py-4 one-line">REGISTRY</label>
                    <label className="font-12 semibold-text px-6 py-4 one-line">ACCOUNT</label>
                    <label className="font-12 semibold-text px-6 py-4 one-line">FRACTIONAL</label>
                    <label className="font-12 semibold-text px-6 py-4 one-line">CO2 TON</label>
                    <label className="font-12 semibold-text px-6 py-4 one-line">STATUS</label>
                    <label className="font-12 semibold-text px-6 py-4 one-line">NFT STATUS</label>
            </div>
            {
            retirementLoading ? 
                <div className="flex flex-1 items-center justify-center mt-4">
                    <img src={Loading} alt="loading" />
                    <label className="bold-text font-16 ml-2 light-purple-text">Loading</label>
                </div>
                    :
            <div className="flex-column campaign-list-camp flex-1">
            {             
                retirements.content?.length > 0 ?
                    <div className="camp-campaign-list" id="scrollableDiv">
                        <InfiniteScroll
                            dataLength={retirements?.content?.length}
                            hasMore={!retirements?.last}
                            loader={
                            <div className="item flex items-center justify-center my-2">
                                <img alt="loading" height="24px" src={Loading} />
                            </div>
                            }
                            next={fetchMoreTransactions}
                            scrollableTarget="scrollableDiv"
                            style={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            overflow: "unset",
                            }}
                        >
                    {retirements.content?.map(ret => 
                        <CampaignRetirement ret={ret} onSelectRetirement={onSelectRetirement} selectAll={selectAll} menuItems={menuItems} onMenuItemClick={onMenuItemClick}   />
                        )}
                        </InfiniteScroll>   
                    </div>
                    :
                <div className="flex items-center justify-center flex-1">
                    <label className="bold-text font-16 light-purple-text">There are no Retirements</label>
                </div>
            }
        </div>
        }
                {showRetirement && <UpdateRetirementPopup ret={showRetirement} onCancel={refresh => {setShowRetirement(null); if(refresh) setN(n+1)}}/>}
        </div>
    )
}

export default CampaignRetirements;