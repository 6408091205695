import React, { useEffect, useState } from 'react';
import moment from "moment";

const NFTMainDetails = ({nft, edit}) => {
    const [formData, setFormData] = useState({
        carbon: '',
        token_type: '',
        mint_date: '',
        owner: '',
        wallet: '',
        tokenId: '',
        nft_type: '',
        blockchain: ''
    });


    const {carbon, token_type, mint_date, owner, wallet, tokenId, nft_type, blockchain} = formData;

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

    useEffect(() => {
        setFormData({
            ...formData,
            token_type: nft && nft.blockchains?.[0]?.token.token_type,
            mint_date: nft && moment.unix(nft.blockchains?.[0]?.minted_date).format("L"),
            nft_type: nft && nft.nft_type,
            owner: nft && nft.owner?.contact?.name,
            wallet: nft && nft.owner?.wallet?.wallet_address,
            tokenId: nft && nft.blockchains?.[0]?.token.id,
            blockchain: nft && nft.blockchains?.[0]?.platform
        })
    }, [nft])

    return (
            <div className={"flex-column"}>
                {!edit ?
                <div className="flex mt-6 border-top">
                    <div className='flex-column border-right' style={{width: "200px"}}>
                        <div className='pl-6 py-3 border-bottom'>
                            <label className='regular-text font-16 light-purple-text'>Mint date</label>
                        </div>
                        <div className='pl-6 py-3 border-bottom'>
                            <label className='regular-text font-16 light-purple-text'>Type</label>
                        </div>
                        <div className='pl-6 py-3 border-bottom'>
                            <label className='regular-text font-16 light-purple-text'>Owner</label>
                        </div>
                        <div className='pl-6 py-3 border-bottom'>
                            <label className='regular-text font-16 light-purple-text'>Wallet</label>
                        </div>
                        <div className='pl-6 py-3 border-bottom'>
                            <label className='regular-text font-16 light-purple-text'>Token ID</label>
                        </div>
                        <div className='pl-6 py-3 border-bottom'>
                            <label className='regular-text font-16 light-purple-text'>Token type</label>
                        </div>
                        <div className='pl-6 py-3 border-bottom'>
                            <label className='regular-text font-16 light-purple-text'>Blockchain</label>
                        </div>
                    </div>
                    <div className='flex-column flex-1'>
                        <div className='pl-6 py-3 border-bottom'>
                            <label className='regular-text font-16'>{nft.blockchains?.[0]?.minted_date ? moment.unix(nft.blockchains?.[0]?.minted_date).format("L") : '-'}</label>
                        </div>
                        <div className='pl-6 py-3 border-bottom'>
                            <label className='regular-text font-16'>{nft.nft_type}</label>
                        </div>
                        <div className='pl-6 py-3 border-bottom'>
                            <label className='regular-text font-16'>{nft.owner?.contact?.name || '-'}</label>
                        </div>
                        <div className='pl-6 py-3 border-bottom'>
                        <label className='regular-text font-16'>{nft.owner?.wallet?.wallet_address || '-'}</label>
                        </div>
                        <div className='pl-6 py-3 border-bottom'>
                        <label className='regular-text font-16'>{nft?.blockchains?.[0]?.token?.id || '-'}</label>
                        </div>
                        <div className='pl-6 py-3 border-bottom'>
                        <label className='regular-text font-16'>{nft?.blockchains?.[0]?.token?.token_type || '-'}</label>
                        </div>
                        <div className='pl-6 py-3 border-bottom'>
                        <label className='regular-text font-16'>{nft?.blockchains?.[0]?.platform || '-'}</label>
                        </div>
                    </div>
                </div>
                    :
                <div className='mxy-6'>
                    <div className={"flex justify-between items-center gap-6"}>
                        <div className={"w-full flex-column"}>
                            <label className="input-label mt-6">Mint date:</label>
                            <input className="input mt-1" value={mint_date} name={'mint_date'} placeholder={'Enter date'}
                                onChange={onChange}/>
                        </div>

                        <div className={"w-full flex-column"}>
                            <label className="input-label mt-3">Type</label>
                            <input className="input mt-1" value={nft_type} name={'nft_type'} placeholder={'Enter type'}
                                onChange={onChange}/>
                        </div>
                    </div>

                    <div className={"flex justify-between items-center gap-6"}>
                        <div className={"w-full flex-column"}>
                            <label className="input-label mt-6">Owner</label>
                            <input className="input mt-1" value={owner} name={'owner'} placeholder={'Enter owner'}
                                onChange={onChange}/>
                        </div>

                        <div className={"w-full flex-column"}>
                            <label className="input-label mt-6">Wallet</label>
                            <input className="input mt-1" value={wallet} name={'wallet'} placeholder={'Enter wallet'}
                                onChange={onChange}/>
                        </div>
                    </div>

                    <div className={"flex justify-between items-center gap-6"}>
                        <div className={"w-full flex-column"}>
                            <label className="input-label mt-6">Token ID</label>
                            <input className="input mt-1" value={tokenId} name={'tokenId'} placeholder={'Enter token ID'}
                                onChange={onChange}/>
                        </div>

                        <div className={"w-full flex-column"}>
                            <label className="input-label mt-6">Type</label>
                            <input className="input mt-1" value={token_type} name={'token_type'} placeholder={'Enter token type'}
                                onChange={onChange}/>
                        </div>
                    </div>

                    <div className={"w-50 flex-column"}>
                        <label className="input-label mt-6">Blockchain</label>
                        <input className="input mt-1" value={blockchain} name={'blockchain'} placeholder={'Enter blockchain'}
                            onChange={onChange}/>
                    </div>
                </div>
                }
            </div>
    )
}

export default NFTMainDetails;
