import React, { useRef } from 'react'
import moment from 'moment';
import PlaceholderImage from '../../assets/images/nft-placeholder.svg'
import { useNavigate } from "react-router-dom";
import Image from "../image";
import { getNftLifecycleColor } from '../../helpers/utils';

import './styles.scss'

const NftGrid = ({nft}) => {
    const navigate = useNavigate();


    return (
        nft ? <div className={"nft-card-grid items-center cursor border-bottom"}
                   onClick={() => navigate(`/meta/nft/${nft.id}`)}>
            <div className={"ncg-item flex justify-start border-right pr-6 pl-2 py-2"}>
                <Image className={"nft-image"} size={85} media={nft?.image?.media} />
                <div className={"flex-column flex-1 ml-4 mt-2"}>
                    <div className="flex justify-between items-center">
                        <label className={"bold-text"}>{nft.name}</label>
                        {/* <span>{nft.series.issue && `${nft.series?.issue} of ${nft.series?.total}`}</span> */}
                    </div>
                    <span className="regular-text font-12 o05 nft-desc mt-2">{nft.description}</span>
                </div>
            </div>

            <div className={"ncg-item flex-column items-start justify-between border-right px-6 py-2"}>
                <div><span className="regular-text o05 mr-1 font-12">Carbon:</span><span className='regular-text font-12'>{nft.nft_type === 'MERCHANDISE' ?  nft.carbon?.[0]?.merchandise?.units : nft.carbon?.[0]?.units} kiloCO2</span></div>
                <div className=''><span className="regular-text o05 mr-1 font-12">Type:</span><span className='regular-text font-12'>{nft.nft_type?.toLowerCase().charAt(0).toUpperCase() + nft.nft_type?.toLowerCase().slice(1)}</span></div>
                <div className=''><span className="regular-text o05 mr-1 font-12">Mint date:</span>{nft.blockchains?.[0]?.minted_date ? <span className='regular-text font-12'>{moment.unix(nft.blockchains?.[0]?.minted_date).format('DD/MM/YYYY')}</span> : <span className='regular-text font-12 o03'>no date</span>}</div>
            </div>

            <div className={"ncg-item flex-column items-start justify-between px-6 relative py-2"}>
                <div className='flex items-center justify-between '>
                    <div><span className="regular-text o05 mr-1 font-12">Owner:</span><span className='regular-text font-12'>{nft.owner?.contact?.name}</span></div>
                    <div className='nft-lifecycle flex items-center justify-center absolute' style={{background: getNftLifecycleColor(nft.lifecycle)}}>
                        <label className='medium-text font-10 white-text'>{nft.lifecycle}</label>
                    </div>
                </div>
                <div className='flex items-center one-line'>
                    <span className="regular-text o05 mr-1 font-12 one-line">Wallet:</span> 
                    <label className='regular-text font-12 one-line'>{nft.owner?.wallet?.wallet_address}</label>
                </div>
                <div className={"flex items-center"}>
                    <div className='flex items-center flex-1'>
                        <span className="regular-text o05 mr-1 font-12">Token:</span>
                        <span className='regular-text font-12 one-line nft-card-token'>{nft.blockchains?.[0]?.token.id}</span>
                    </div>
                    <div className='flex items-center flex-1 ml-4'>
                        <span className="regular-text o05 mr-1 font-12">Type:</span>
                        <span className='regular-text font-12'>{nft.blockchains?.[0]?.token.token_type}</span>
                    </div>
                </div>
            </div>
        </div> : <></>
    )
}

export default NftGrid;
