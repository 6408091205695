import React from 'react'

import { useNavigate } from 'react-router-dom';

import './style.scss'

const Reports = () => {
    const navigate = useNavigate();

    const reports = [
        {id: "retirements", name: "Retirements", description: "An overview of retirements processed for each inventory"},
        {id: "sales", name: "Sales", description: "An overview of sales on a per project including those not retired"},
        {id: "combination", name: "Combination", description: "Combined report of inventory and sales"}
    ]
    return(
        <div className='flex-column pxy-6 flex-1'>
            <label className='bold-text font-20'>Carbon</label>
            <div className='flex mt-6'>
                <label className='regular-text font-16 flex-1 light-purple-text mr-12'>Track the sales and retirements of your carbon inventory.</label>
                <div className='flex-1'>
                    {reports.map(r =>
                        <div key={r.id} className='flex-column report-card  mb-4 px-4 py-3 cursor' onClick={() => navigate(`/reports/${r.id}`)}>
                                <label className='regular-text font-16'>{r.name}</label>
                                <label className='regular-text font-12 o03 mt-2'>{r.description}</label>
                        </div>
                        )}
                </div>
            </div>
        </div>
    )
}

export default Reports;